import { atom } from "recoil";


export const placesState=atom({
    key:"places-state",
    default:[{
      id:"",
      numero:"",
}]
  })
